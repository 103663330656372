header {
    position: relative;
    transition: all 500ms cubic-bezier(0.17, 0.67, 0.6, 0.93);
    border-bottom: 1px solid var(--border-light);

    .logo {
        flex-shrink: 0;
        margin-right: var(--space-12);
        width: 138px;
        a {
            display: block;
            height: 48px;
            background: url('../../../assets/images/common/logo.svg') no-repeat center/ contain;
            font-size: 0px;
            text-indent: -9999rem;
        }
    }

    // 1depth
    .menu-nav {
			display: flex;
        a {
            position: relative;
            padding: var(--space-8) var(--space-10);
            color: var(--font-darken);
            text-align: center;

            &:hover,
            &:focus,
            &.active {
                color: var(--color-primary);
                text-decoration: underline;
                text-underline-position : under;
                text-underline-offset : 3px;
            }
        }
    }

    // site-map icon
    .site-map {
        flex-shrink: 0;
        margin-left: var(--space-5);
        padding: 0;
        width: 36px;
        height: 36px;
        border-radius: 0;
        background: url('../../../assets/images/icon/icon-menu.svg') no-repeat center / contain;
        font-size: 0px;
        text-indent: -9999rem;
        transition: all 0.4s;
        &.active {
            background-image: url('../../../assets/images/icon/icon-close.svg');
        }
    }
    .extra {
        margin-left: auto;
    }

    // 2depth
    .menu-wrap,
    .site-menu-wrap {
        width: 100%;
        min-height: 280px;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1001;
        border-top: 1px solid var(--border-light);
        background-color: var(--bg-white);
        transition: all 250ms cubic-bezier(0.17, 0.67, 0.6, 0.93);
    }

    .menu-wrap {
			font-weight: 700;
			font-size: var(--font-size-5);
			.menu-list {
				display: flex;
				flex-direction: column;
				padding: var(--space-10) 0;

					a {
							display: flex;
							align-items: center;
							gap: var(--space-2);
							min-width: 542px;
							text-align: left;
							word-break: keep-all;
							padding: var(--space-3) var(--space-5);
							border-bottom: 1px solid var(--border-light);
							margin-bottom: var(--space-2);
							&:last-child {
									border-bottom: none;
									margin-bottom: 0;
							}
							&.selected {
									color: var(--color-primary);
									.icon {
											background: url('../../../assets/images/icon/icon-check-point.svg') no-repeat center / contain;
											margin-left: unset;
									}
							}
					}

					.icon {
							width: 24px;
							height: 24px;
							margin-left: auto;
							font-size: 0;
							background: url('../../../assets/images/icon/icon-arrow-right.svg') no-repeat center / contain;
					}
			}

			.menu-desc {
					padding: var(--space-10) 0 var(--space-10) var(--space-10);
					.text-wrap {
							span {
									color: #666; //var(--font-light);
									margin-bottom: var(--space-2);
							}
							.contents {
									max-width: 268px;
									color: var(--gray-11);
									border-top: 1px dashed var(--border-light);
									padding-top: var(--space-3);
									margin-top: var(--space-15);
							}
					}
			}
    }

    .menu {
			display: flex;
			flex-direction: row-reverse;
			justify-content: center;
        // margin-left: 186px;
    }

    .site-menu-wrap {
        min-height: unset;
        background-color: var(--bg-light);
        opacity: 0;
        transform: scaleY(0);
        transform-origin: 0 0;

        &.open {
            opacity: 1;
            transform: scaleY(1);
        }

        a {
            display: block;
            padding: calc(var(--space-2) + 2px) var(--space-2);
            color: var(--font-darken);
            text-align: center;
            word-break: keep-all;

            &:hover,
            &:focus,
            &.active {
							background-color: rgba($color: #000000, $alpha: 0.1);
							font-weight: 700;
                // color: var(--font-white);
            }
        }
				.menu{
					flex-direction: row;
				}

        .depth-items {
            padding: var(--space-5) 0;
            //width: 20%;
            border-right: 1px solid var(--border-light);
            border-bottom: 1px solid var(--border-light);
            &:first-of-type {
                border-left: 1px solid var(--border-light);
            }
            &.active {
							background-color: var(--color-primary);
                
                a {
                    color: var(--font-white);
                    &:hover,
                    &:focus,
                    &.active {
											// background-color: var(--color-primary);
											background-color: rgba($color: #000000, $alpha: 0.2);
                      font-weight: var(--font-weight-bold);
                    }
                }
            }
        }
    }
}

[data-device='pc'] {
    header {
        &.open {
            .header-inner {
                position: relative;
            }

            nav,
            .site-menu-wrap {
                a {
                    width: calc(1214px / 6);
                }
            }

            .site-menu-wrap {
                a {
                    font-size: var(--font-size-4);
                    // font-weight: var(--font-weight-regular);
                }
            }

            .site-map {
                margin-left: 0;
            }
            .extra {
                display: none;
                &.show {
                    display: block;
                    position: absolute;
                    top: auto;
                    right: -36px;
                    animation: motion-hamburger 1s;
                }
            }
        }
    }
}

[data-device='mobile'] {
    header {
        z-index: 1001;
        &.open {
            position: fixed;
            width: 100%;
            height: 100%;
            + main {
                padding-top: 91px;
            }
            .gnb-wrap {
                display: block;
            }
        }

        .header-inner {
            padding: calc(var(--space-5) + 1px) var(--space-5);
            border-bottom: 1px solid var(--border-light);
            background-color: var(--bg-white);
            .ant-input-group-wrapper {
                display: none;
            }
            .extra {
                margin-top: 0;
                margin-left: auto;
            }
        }

        .search-wrap {
            display: none;
        }

        .gnb-wrap {
            display: none;
            position: fixed;
            top: auto;
            overflow-y: auto;
            width: 100%;
            height: calc(100% - 91px);
            background-color: var(--bg-white);
            transition: all 0.2s;
            animation: motion-menu 0.5s;
            .rt-ContainerInner {
                height: 100%;
            }
            .rt-r-fd-column {
                height: 100%;
            }
        }

        .gnb {
            padding: var(--space-5) 0;
            border: 0;
            background-color: inherit;
            button {
                display: flex;
                align-items: center;
                padding-top: var(--space-3);
                padding-bottom: var(--space-3);
                padding-left: var(--space-3);
                border-bottom: 1px solid var(--border-light);
                width: 100%;
                &[data-state='open'] {
                    border-color: var(--color-primary);
                    color: var(--color-primary);
                    span {
                        background-color: var(--color-primary);
                        &::after {
                            content: none;
                        }
                    }
                }
                span {
                    display: inline-block;
                    position: relative;
                    margin-left: auto;
                    width: 36px;
                    height: 36px;
                    background-color: var(--bg-darken);
                    font-size: 0;
                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 28px;
                        height: 2px;
                        background-color: var(--bg-white);
                        transform: translate(-50%, -50%);
                    }
                    &::after {
                        width: 2px;
                        height: 28px;
                    }
                }
            }
        }

        .gnb-items {
            padding: var(--space-4) 0;
            background-color: var(--bg-light);
            a {
                display: block;
                padding: var(--space-2) var(--space-6);
                color: var(--font-darken);
                &.active {
                    color: var(--color-primary);
                }
            }
        }

        .login-box {
            padding-bottom: var(--space-5);
            margin-left: 0;
            margin-top: auto;
        }

        .login {
            margin-top: var(--space-6);
            width: 100%;
            height: 68px;
            border-radius: 0;
            background-color: var(--color-primary);
            color: var(--font-white);
            font-size: var(--font-size-1);
            font-weight: var(--font-weight-bold);
            line-height: calc(var(--default-font-size) * 1.375); // 22px
        }
    }

    @media screen and (min-width: 1080px) {
        header {
            &.open {
                + main {
                    padding-top: 113px;
                }
            }

            .header-inner {
                padding: var(--space-8) var(--space-10);
                border-color: var(--border-light);
                .ant-input-group-wrapper {
                    display: block;
                }
            }
            .search-wrap {
                display: none;
            }

            .gnb-wrap {
                height: calc(100% - 113px);
                .container {
                    padding: 0 var(--space-10);
                }
            }
        }
    }
}

@keyframes motion-menu {
    0% {
        right: -100%;
    }
    100% {
        right: 0;
    }
}

@keyframes motion-hamburger {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
