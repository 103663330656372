/**
 * 엑센트 컬러
 */
:root,
.light,
.light-theme {
    --teal-1: #f9fefe;
    --teal-2: #f1fbfa;
    --teal-3: #daf9f7;
    --teal-4: #c4f5f1;
    --teal-5: #acede8;
    --teal-6: #92e0db;
    --teal-7: #70d0cb;
    --teal-8: #28bcb6;
    --teal-9: #31c1bb;
    --teal-10: #1bb5b0;
    --teal-11: #007f7a;
    --teal-12: #003e3c;

    --teal-a1: #00d5d506;
    --teal-a2: #00b7a40e;
    --teal-a3: #00d6c825;
    --teal-a4: #01d4c33b;
    --teal-a5: #00c8b953;
    --teal-a6: #00b7ab6d;
    --teal-a7: #00aca38f;
    --teal-a8: #00b0a9d7;
    --teal-a9: #00b2abce;
    --teal-a10: #00aca7e4;
    --teal-a11: #007f7a;
    --teal-a12: #003e3c;

    --teal-contrast: #fff;
    --teal-surface: #eefaf9cc;
    --teal-indicator: #31c1bb;
    --teal-track: #31c1bb;
}

@supports (color: color(display-p3 1 1 1)) {
    @media (color-gamut: p3) {
        :root,
        .light,
        .light-theme {
            --teal-1: oklch(99.4% 0.0057 190.7);
            --teal-2: oklch(98.1% 0.0108 190.7);
            --teal-3: oklch(96.1% 0.0325 190.7);
            --teal-4: oklch(93.5% 0.0503 190.7);
            --teal-5: oklch(90% 0.0652 190.7);
            --teal-6: oklch(85.6% 0.0773 190.7);
            --teal-7: oklch(79.7% 0.0917 190.7);
            --teal-8: oklch(72.1% 0.1167 190.7);
            --teal-9: oklch(73.7% 0.1167 190.7);
            --teal-10: oklch(70.1% 0.1167 190.7);
            --teal-11: oklch(52.8% 0.1167 190.7);
            --teal-12: oklch(32.8% 0.0602 190.7);

            --teal-a1: color(display-p3 0.0196 0.8039 0.8039 / 0.02);
            --teal-a2: color(display-p3 0.0235 0.6745 0.5922 / 0.048);
            --teal-a3: color(display-p3 0.0039 0.7765 0.7451 / 0.122);
            --teal-a4: color(display-p3 0.0078 0.7804 0.7176 / 0.193);
            --teal-a5: color(display-p3 0.0039 0.7137 0.6706 / 0.271);
            --teal-a6: color(display-p3 0.0039 0.6392 0.5961 / 0.357);
            --teal-a7: color(display-p3 0.0039 0.5882 0.5569 / 0.467);
            --teal-a8: color(display-p3 0 0.5725 0.5451 / 0.64);
            --teal-a9: color(display-p3 0 0.5882 0.5647 / 0.62);
            --teal-a10: color(display-p3 0 0.549 0.5294 / 0.667);
            --teal-a11: color(display-p3 0 0.349 0.3294 / 0.785);
            --teal-a12: color(display-p3 0 0.1647 0.1608 / 0.91);

            --teal-contrast: #fff;
            --teal-surface: color(display-p3 0.9412 0.9804 0.9765 / 0.8);
            --teal-indicator: oklch(73.7% 0.1167 190.7);
            --teal-track: oklch(73.7% 0.1167 190.7);
        }
    }
}

/**
  세컨 컬러
 */

:root,
.light,
.light-theme {
    --red-1: #fffcfb;
    --red-2: #fff6f4;
    --red-3: #ffeae3;
    --red-4: #ffd7cb;
    --red-5: #ffc8b8;
    --red-6: #ffb7a3;
    --red-7: #ffa48f;
    --red-8: #f68971;
    --red-9: #ff7053; //#f94f29;
    --red-10: #ec3e13;
    --red-11: #db3000;
    --red-12: #5d281c;

    --red-a1: #ff400004;
    --red-a2: #ff2f000b;
    --red-a3: #ff40001c;
    --red-a4: #ff3b0034;
    --red-a5: #ff3a0047;
    --red-a6: #ff38005c;
    --red-a7: #ff300070;
    --red-a8: #ef2b008e;
    --red-a9: #f82d00d6;
    --red-a10: #eb2f00ec;
    --red-a11: #db3000;
    --red-a12: #490e00e3;

    --red-contrast: #fff;
    --red-surface: #fff4f1cc;
    --red-indicator: #f94f29;
    --red-track: #f94f29;
}

@supports (color: color(display-p3 1 1 1)) {
    @media (color-gamut: p3) {
        :root,
        .light,
        .light-theme {
            --red-1: oklch(99.3% 0.0031 33.87);
            --red-2: oklch(98.1% 0.0121 33.87);
            --red-3: oklch(95.6% 0.0306 33.87);
            --red-4: oklch(92.3% 0.0684 33.87);
            --red-5: oklch(89.1% 0.0914 33.87);
            --red-6: oklch(85.5% 0.1017 33.87);
            --red-7: oklch(80.4% 0.1127 33.87);
            --red-8: oklch(74.3% 0.1374 33.87);
            --red-9: oklch(66.1% 0.2124 33.87);
            --red-10: oklch(62.2% 0.2154 33.87);
            --red-11: oklch(58% 0.2116 33.87);
            --red-12: oklch(34.8% 0.0817 33.87);

            --red-a1: color(display-p3 0.7569 0.2667 0.0235 / 0.016);
            --red-a2: color(display-p3 0.8235 0.2 0.0196 / 0.044);
            --red-a3: color(display-p3 0.8549 0.2627 0.0039 / 0.106);
            --red-a4: color(display-p3 0.8824 0.2275 0.0078 / 0.197);
            --red-a5: color(display-p3 0.8706 0.2235 0.0039 / 0.267);
            --red-a6: color(display-p3 0.8745 0.2196 0.0039 / 0.346);
            --red-a7: color(display-p3 0.8784 0.1882 0.0039 / 0.42);
            --red-a8: color(display-p3 0.8235 0.1725 0 / 0.534);
            --red-a9: color(display-p3 0.8745 0.1725 0 / 0.773);
            --red-a10: color(display-p3 0.8235 0.1608 0 / 0.832);
            --red-a11: color(display-p3 0.7608 0.149 0 / 0.875);
            --red-a12: color(display-p3 0.2471 0.0549 0 / 0.879);

            --red-contrast: #fff;
            --red-surface: color(display-p3 1 0.9569 0.9451 / 0.8);
            --red-indicator: oklch(66.1% 0.2124 33.87);
            --red-track: oklch(66.1% 0.2124 33.87);
        }
    }
}

/**
   써드 컬러
 */

:root,
.light,
.light-theme {
    --blue-1: #fbfdff;
    --blue-2: #f3f9ff;
    --blue-3: #e5f3ff;
    --blue-4: #d7ecff;
    --blue-5: #c6e2ff;
    --blue-6: #b0d4ff;
    --blue-7: #93c2ff;
    --blue-8: #6aa8ff;
    --blue-9: #003f98;
    --blue-10: #1751ac;
    --blue-11: #1e6ce4;
    --blue-12: #002981;

    --blue-a1: #0080ff04;
    --blue-a2: #0080ff0c;
    --blue-a3: #008aff1a;
    --blue-a4: #0086ff28;
    --blue-a5: #007eff39;
    --blue-a6: #0075ff4f;
    --blue-a7: #006fff6c;
    --blue-a8: #006aff95;
    --blue-a9: #003f98;
    --blue-a10: #0040a4e8;
    --blue-a11: #0058e0e1;
    --blue-a12: #002981;

    --blue-contrast: #fff;
    --blue-surface: #f0f8ffcc;
    --blue-indicator: #003f98;
    --blue-track: #003f98;
}

@supports (color: color(display-p3 1 1 1)) {
    @media (color-gamut: p3) {
        :root,
        .light,
        .light-theme {
            --blue-1: oklch(99.3% 0.0053 259.5);
            --blue-2: oklch(98.2% 0.0155 259.5);
            --blue-3: oklch(96% 0.0323 259.5);
            --blue-4: oklch(93.7% 0.0602 259.5);
            --blue-5: oklch(90.6% 0.0869 259.5);
            --blue-6: oklch(86.4% 0.1114 259.5);
            --blue-7: oklch(80.9% 0.1437 259.5);
            --blue-8: oklch(73.5% 0.1968 259.5);
            --blue-9: oklch(39.4% 0.1568 259.5);
            --blue-10: oklch(45.5% 0.1568 259.5);
            --blue-11: oklch(55.8% 0.1968 259.5);
            --blue-12: oklch(32.4% 0.1568 259.5);

            --blue-a1: color(display-p3 0.0235 0.5137 1 / 0.016);
            --blue-a2: color(display-p3 0.0196 0.4667 0.9137 / 0.044);
            --blue-a3: color(display-p3 0.0118 0.4863 0.9608 / 0.091);
            --blue-a4: color(display-p3 0.0078 0.451 0.9451 / 0.142);
            --blue-a5: color(display-p3 0.0039 0.4235 0.9451 / 0.204);
            --blue-a6: color(display-p3 0.0039 0.3922 0.9451 / 0.283);
            --blue-a7: color(display-p3 0.0039 0.3686 0.949 / 0.385);
            --blue-a8: color(display-p3 0 0.3412 0.949 / 0.53);
            --blue-a9: color(display-p3 0 0.1686 0.5294 / 0.91);
            --blue-a10: color(display-p3 0 0.1843 0.5843 / 0.844);
            --blue-a11: color(display-p3 0 0.2588 0.8275 / 0.789);
            --blue-a12: color(display-p3 0 0.1098 0.4588 / 0.95);

            --blue-contrast: #fff;
            --blue-surface: color(display-p3 0.949 0.9725 1 / 0.8);
            --blue-indicator: oklch(39.4% 0.1568 259.5);
            --blue-track: oklch(39.4% 0.1568 259.5);
        }
    }
}

/**
   그레이 컬러
 */

:root,
.light,
.light-theme {
    --gray-1: #fcfcfc;
    --gray-2: #f9f9f9;
    --gray-3: #efefef;
    --gray-4: #e8e8e8;
    --gray-5: #e0e0e0;
    --gray-6: #d8d8d8;
    --gray-7: #cecece;
    --gray-8: #484848;
    --gray-9: #8d8d8d;
    --gray-10: #838383;
    --gray-11: #666666;
    --gray-12: #202020;

    --gray-a1: #00000003;
    --gray-a2: #00000006;
    --gray-a3: #00000010;
    --gray-a4: #00000017;
    --gray-a5: #0000001f;
    --gray-a6: #00000027;
    --gray-a7: #00000031;
    --gray-a8: #00000044;
    --gray-a9: #00000072;
    --gray-a10: #0000007c;
    --gray-a11: #0000009b;
    --gray-a12: #000000df;

    --gray-contrast: #ffffff;
    --gray-surface: #ffffffcc;
    --gray-indicator: #8d8d8d;
    --gray-track: #8d8d8d;
}

@supports (color: color(display-p3 1 1 1)) {
    @media (color-gamut: p3) {
        :root,
        .light,
        .light-theme {
            --gray-1: oklch(99.1% 0 none);
            --gray-2: oklch(98.1% 0 none);
            --gray-3: oklch(95.3% 0 none);
            --gray-4: oklch(93% 0 none);
            --gray-5: oklch(90.8% 0 none);
            --gray-6: oklch(88.4% 0 none);
            --gray-7: oklch(85.1% 0 none);
            --gray-8: oklch(79.1% 0 none);
            --gray-9: oklch(64.3% 0 none);
            --gray-10: oklch(60.9% 0 none);
            --gray-11: oklch(50.3% 0 none);
            --gray-12: oklch(24.3% 0 none);

            --gray-a1: color(display-p3 0 0 0 / 0.0118);
            --gray-a2: color(display-p3 0 0 0 / 0.0235);
            --gray-a3: color(display-p3 0 0 0 / 0.0627);
            --gray-a4: color(display-p3 0 0 0 / 0.0902);
            --gray-a5: color(display-p3 0 0 0 / 0.1216);
            --gray-a6: color(display-p3 0 0 0 / 0.1529);
            --gray-a7: color(display-p3 0 0 0 / 0.1922);
            --gray-a8: color(display-p3 0 0 0 / 0.2667);
            --gray-a9: color(display-p3 0 0 0 / 0.4471);
            --gray-a10: color(display-p3 0 0 0 / 0.4863);
            --gray-a11: color(display-p3 0 0 0 / 0.6078);
            --gray-a12: color(display-p3 0 0 0 / 0.8745);

            --gray-contrast: #ffffff;
            --gray-surface: color(display-p3 1 1 1 / 80%);
            --gray-indicator: oklch(64.3% 0 none);
            --gray-track: oklch(64.3% 0 none);
        }
    }
}

/**
  시멘틱 변수들
 */
.radix-themes {
    --border-white: var(--gray-contrast);
    --border-light: var(--gray-4);
    --border-basic: var(--gray-7);
    --border-dark: #666;
    --border-darken: var(--gray-12);
    --border-light-blue: var(--blue-6);

    --bg-light: var(--gray-2);
    --bg-lighten: #fbfbfb;
    --bg-light-gray: #e6e6e6;
    --bg-gray: #a6a6a6;
    --bg-darken: var(--gray-10);
    --bg-dark: var(--gray-11);
    --bg-darkness: var(--gray-12);
    --bg-white: var(--gray-contrast);
    --bg-light-blue: var(--blue-2);
    --bg-blue: var(--blue-3);
    --bg-dark-blue: #192642;
    --bg-light-sky: #e2f1fc;
    --bg-sky: #b9dbf8;
    --bg-lightness-blue: var(--blue-6);
    --bg-yellow: #fdfbf3;
    --bg-mustard: #ffaf3f;
    --bg-red: var(--red-1);
    --bg-green: #edf5e7;
    --bg-purple: #fdf5f3;
    --bg-light-violet: #afb6e4;
    --bg-violet: #7951d7;
    --bg-light-mint: var(--teal-3);
    --bg-lighten-mint: #00b3ac;
    --bg-mint: var(--teal-10);
    --bg-dark-mint: var(--teal-11);
    --bg-lighten-blue: #107fdc;
    --bg-darken-blue: #075db7;
    --bg-navy: #30487b;

    --color-primary: #0C847E; //var(--accent-9);
    --color-secondary: var(--red-9);
    --color-third: var(--blue-9);
    --color-error: #d92f0d; //var(--red-10);
    --color-info: #0076d1; //#128de9;

    /* elements color */
    --elements-orange: #ff9f2f;
    --elements-yellow: #ffdb5f; //#ffcf26;
    --elements-green: #93cd3d;
    --elements-blue: #71aeff; // #4593f9;
    --elements-mint: #a7e7de; //#62ddcc;
    --elements-violet: #c79bff; //#9d68f2;
    --elements-peach: #ffb2aa; //#ffa69d;

    /* sw CI color */
    --sw-dark-blue: var(--blue-9);
    --sw-orange: #f95029;
    --sw-yellow: #f9a61a;
    --sw-green: #0C847E; //#00a885;
    --sw-mint: #afe4e3;
    --sw-violet: #392d88;
    --sw-light-blue: #0378D4; //#2073d1;
    --sw-bg-line-blue: #a8ceff;
    --sw-bg-line-green: #b9e37b;

    --font-white: var(--gray-contrast);
    --font-light: var(--gray-9);
    --font-basic: #666; //var(--gray-11);
    --font-dark: var(--gray-12);
    --font-darken: var(--gray-12);
    --font-point: var(--accent-9);
    --font-error: var(--color-error); //var(--red-9);
    --font-disabled: var(--gray-5);

    --font-color-white: var(--gray-contrast);
    --font-color-light: var(--gray-7);
    --font-color-basic: var(--gray-11);
    --font-color-darken: var(--gray-12);
    --font-color-point: var(--accent-9);
    --font-color-error: var(--red-9);
    --font-color-disabled: var(--gray-5);

    /* badge color */
    --bg-avatar: var(--color-primary);
    --bg-chart: var(--color-error);
    --bg-clock: var(--color-info);

    /* shadow-line */
    --shadow-line-light: 0 0 0 1px var(--border-light);
    --shadow-line-darken: 0 0 0 1px var(--border-darken);
}

.font-primary {
    color: var(--font-color-point);
}
.font-info {
    color: var(--color-info);
}
.font-error {
    color: var(--font-error);
}
.font-basic {
    color: var(--font-basic);
}
.font-dark {
    color: var(--font-darken);
}
