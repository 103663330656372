.enforce {
    overflow: hidden;
    .area {
        display: flex;
        gap: var(--space-9);
        margin-bottom: var(--space-20);
        padding-left: var(--space-9);
        padding-bottom: var(--space-20);
        border-bottom: 1px solid var(--border-basic);

        strong {
            color: var(--color-primary);
        }

        .title-box {
            flex-shrink: 0;
            width: 264px;
            color: var(--font-darken);
            &.size-small {
                width: 204px;
            }
        }

        .space {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            gap: var(--space-2);
            color: var(--font-basic);

            .type-row {
                flex-direction: row;
            }

            .wrap-investment {
                display: flex;
                gap: var(--space-14);
            }

            .wrap-cube {
                position: relative;
                color: var(--font-darken);
                padding-left: var(--space-9);
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: var(--space-6);
                    height: var(--space-7);
                    background-image: url('../../../assets/images/pages/policy/img-deco-cube.svg');
                }
            }
            .wrap-box {
                display: flex;
                justify-content: space-between;
                text-align: center;
                gap: var(--space-3);

                &.center {
                    align-items: center;
                }

                .investment {
                    text-align: center;
                    .wrap-investment-box {
                        background-color: var(--sw-light-blue); //var(--teal-7);
                        color: var(--font-white);
                        display: flex;
                        flex-direction: column;
                        width: 320px;
                        height: 110px;
                        justify-content: center;
                        align-items: center;
                        p {
                            text-align: center;
                        }
                    }
                }

                &.one {
                    display: flex;
                    flex-direction: column;
                }

                .arrow-right {
                    display: flex;
                    align-items: center;
                }

                .present {
                    width: 440px;
                    border: 1px solid var(--border-basic);

                    hr {
                        display: block;
                        margin-block: var(--space-2);
                        border-top: 1px dashed var(--border-basic);
                    }

                    &.common {
                        width: 304px;
                    }
                    .top {
                        padding-block: var(--space-1);
                        background: var(--bg-dark);
                        color: var(--font-white);
                    }
                    .mid {
                        padding-block: var(--space-1);
                        background: var(--bg-light); //var(--bg-darken);
                        color: var(--font-darken); //var(--font-white);
                        border-bottom: 1px solid var(--border-basic);
                    }
                    .bottom {
                        min-height: 120px;
                        text-align: center;
                        color: var(--font-darken);
                    }
                }
                .improvement {
                    width: 440px;
                    border: 1px solid var(--border-basic);
                    hr {
                        display: block;
                        margin-block: var(--space-2);
                        border-top: 1px dashed var(--border-basic);
                    }
                    .top {
                        padding-block: var(--space-1);
                        background: var(--bg-dark-mint);
                        color: var(--font-white);
                    }
                    .mid {
                        padding-block: var(--space-1);
                        background: var(--bg-mint);
                        color: var(--font-darken); //var(--font-white);
                        // p {
                        //     color: var(--font-white);
                        // }
                    }
                    .bottom {
                        min-height: 120px;
                        text-align: center;
                        color: var(--font-darken);
                    }
                }
            }
            .wrap-arrow {
                display: flex;
                align-items: center;
                gap: var(--space-2);
                padding: 0 var(--space-9);
                margin-top: var(--space-4);
                .arrow {
                    display: inline-block;
                    width: var(--space-4);
                    height: var(--space-4);
                    margin-inline: var(--space-1);
                    background-image: url('../../../assets/images/pages/policy/icon-arrow-right-small.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            .section-desc {
                display: flex;
                flex-direction: column;
                gap: var(--space-15);
                margin-top: var(--space-20);
                &:first-child {
                    margin-top: 0;
                }

                .title4 {
                    color: var(--font-darken);
                }

                .arrow {
                    display: flex;
                    height: var(--space-6);
                    padding: var(--space-2) var(--space-1);
                    justify-content: center;
                    align-items: center;
                }

                article {
                    display: flex;
                    flex-direction: column;
                    gap: var(--space-4);

                    .text-sub-title2 {
                        position: relative;
                        padding-left: var(--space-5);
                        &::before {
                            content: '※';
                            position: absolute;
                            top: 0;
                            left: 0;
                            font-size: inherit;
                            color: inherit;
                        }
                    }
                }
                ul {
                    margin-top: var(--space-6);
                    padding: 0 var(--space-9);
                    li {
                        list-style-type: none; /* 기본 bullet 숨김 */
                        position: relative;
                        padding-left: var(--space-6);
                        padding-bottom: var(--space-2);
                        color: var(--font-basic);

                        &::before {
                            content: '';
                            width: var(--space-2);
                            height: var(--space-2);
                            background-color: var(--color-primary);
                            position: absolute;
                            left: var(--space-1);
                            top: var(--line-height-1);
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
        .desc {
            color: var(--font-basic);
            margin-top: var(--space-4);
            padding-left: var(--space-9);
        }
        &.local-state {
            .box-wrap {
                display: flex;
                justify-content: space-between;
                width: 100%;
                .box {
                    color: var(--font-white);
                    display: flex;
                    flex-direction: column;
                    flex: 1 1;
                    max-width: 336px;
                    height: 476px;
                    padding: var(--space-9) var(--space-6);
                    img {
                        width: 103px;
                        height: 103px;
                    }

                    .sub-title2 {
                        position: relative;
                        margin-top: auto;
                        padding-left: var(--space-5);
                        word-break: keep-all;
                        &::before {
                            content: '※';
                            position: absolute;
                            top: 0;
                            left: 0;
                            font-size: inherit;
                            color: inherit;
                        }
                    }

                    &.primary {
                        background-color: var(--color-primary);
                    }

                    &.sky {
                        background-color: var(--color-info);
                    }

                    &.blue {
                        background-color: var(--color-third);
                    }
                }
            }
        }

        .gray-box {
            background: var(--bg-lighten);
        }
    }
}
.enforce-direction {
    overflow: hidden;
    .direction-box {
        display: flex;
        flex-direction: column;
        border: 1px solid var(--border-basic);
        background-color: var(--bg-lighten);
    }

    .key-task-wrapper {
        margin-top: calc(var(--space-5) * 5);
        .key-task {
            display: flex;
            justify-content: center;
            gap: var(--space-13);
            margin-top: var(--space-13);

            .key-task-box {
                width: 638px;
                padding: var(--space-10) var(--space-8);

                .titles {
                    text-align: center;
                    color: var(--font-white);
                    margin-bottom: var(--space-14);
                }

                .title-box {
                    position: absolute;
                    top: -16px;
                    left: 50%;
                    transform: translateX(-50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 496px;
                    height: 52px;
                    color: var(--font-white);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                &.primary {
                    position: relative;
                    background: var(--color-primary); //var(--bg-lighten-mint);
                    .title-box {
                        background-image: url('../../../assets/images/pages/policy/bg-key-task-header.svg');
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 100%;
                        width: 66px;
                        height: 107px;
                        background-image: url('../../../assets/images/pages/policy/img-red-arrow.svg');
                    }
                }
                &.blue {
                    background: var(--bg-lighten-blue);
                    .title-box {
                        background-image: url('../../../assets/images/pages/policy/bg-key-task-header-blue.svg');
                    }
                }

                .key-task-box-flex {
                    display: flex;
                    flex-direction: column;
                    gap: var(--space-14);
                    position: relative;

                    .arrow-bottom {
                        position: absolute;
                        bottom: 46%;
                        left: 42%;
                    }

                    .key-task-area {
                        position: relative;
                        width: 100%;
                        min-height: 290px;
                        padding-top: var(--space-16);
                        padding-right: var(--space-5);
                        padding-bottom: var(--space-9);
                        padding-left: var(--space-12);
                        background-color: var(--bg-lighten);
                        border-radius: var(--space-6);
                        border: 6px solid white;

                        &.is-arrow {
                            &::after {
                                content: '';
                                position: absolute;
                                z-index: 1;
                                top: 100%;
                                left: 50%;
                                transform: translateX(-50%);
                                width: 76px;
                                height: 56px;
                                background-image: url('../../../assets/images/pages/policy/img-white-arrow.svg');
                            }
                        }
                        ul {
                            counter-reset: counter;
                            display: flex;
                            flex-direction: column;
                            gap: var(--space-4);
                            margin-top: var(--space-7);
                            li {
                                counter-increment: counter;
                                position: relative;
                                padding-left: var(--space-11);
                                color: var(--font-basic);
                                &::before {
                                    content: counter(counter);
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: var(--space-8);
                                    height: var(--space-8);
                                    border-radius: 100%;
                                    background-color: var(--bg-darken);
                                    font-size: var(--font-size-4);
                                    color: var(--font-white);
                                    font-weight: var(--font-weight-bold);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .box-img {
        position: relative;
        height: 354px;
        background-image: url('../../../assets/images/pages/policy/bg-enforce-direction.png');

        .area-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            text-align: center;
            color: var(--font-white);
            strong {
							display: inline-block;
                color: var(--teal-6); //var(--color-primary);
								background-color: rgba($color: #000000, $alpha: 0.5);
								padding:0 12px 4px;
            }
        }
    }
    .wrap-goals {
        display: flex;
        justify-content: center;
        margin-top: var(--space-11);
        .rt-Separator {
            height: 220px;
        }
        .box-goals {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 370px;
            text-align: center;
            h6 {
                color: var(--font-darken);
                padding-bottom: var(--space-5);
            }
            p:not(.title6, .title7) {
                color: var(--font-basic);
                display: flex;
                align-items: center;
                justify-content: center;
                .arrow {
                    display: inline-block;
                    width: var(--space-4);
                    height: var(--space-4);
                    margin-inline: var(--space-1);
                    background-image: url('../../../assets/images/pages/policy/icon-arrow-right-small.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            .box-goals-em {
                position: relative;

                .em {
                    color: var(--font-darken);
                    text-align: center;
                    font-size: 64px;
                    font-weight: var(--font-weight-bold);
                    line-height: 80px;
                    margin-bottom: var(--space-11);
                }

                .money-icon {
                    position: absolute;
                    right: 0;
                    top: var(--space-10);
                }
                .rest-icon {
                    position: absolute;
                    right: 23px;
                    top: 22px;
                }
                .arrow-up-icon {
                    position: absolute;
                    right: 38px;
                    top: 10px;
                }
            }
        }

        em {
            color: var(--font-darken);
            text-align: center;
            font-size: 64px;
            font-weight: 700;
            line-height: 80px;

            padding-bottom: var(--space-11);
        }
    }
    .goal-wrapper {
        margin-top: calc(var(--space-5) * 5);
    }
}
.digital-view-box {
    overflow: hidden;
    padding-block: var(--space-12);
    background-color: var(--bg-lighten);
}

html[data-device='mobile'] {
    .enforce {
        .area {
            flex-direction: column;
            margin-bottom: var(--space-16);
            padding-bottom: var(--space-16);
            padding-left: 0;
            .title-box {
                width: auto;
            }
            .space {
                padding-right: 0;
                &.type-column {
                    gap: var(--space-10);
                }
                .section-desc {
                    gap: var(--space-9);
                    margin-top: var(--space-9);
                    & > p {
                        color: var(--font-darken);
                    }
                    ul {
                        margin-top: var(--space-4);
                        padding: 0;
                        li {
                            padding-left: var(--space-6);
                        }
                    }
                }
                .wrap-box {
                    flex-direction: column;
                    .arrow-right {
                        justify-content: center;
                        img {
                            transform: rotateZ(90deg);
                        }
                    }
                    .present,
                    .improvement {
                        width: 100%;
                        .bottom {
                            padding-inline: 0;
                        }
                    }
                    .investment {
                        width: 100%;
                    }
                    .wrap-investment-box {
                        width: 100%;
                    }
                }
            }
            .wrap-cube {
                padding-left: 0;
                &::before {
                    display: none;
                }
            }
            .wrap-arrow {
                padding: 0;
                align-items: flex-start;
            }
            .desc {
                margin-top: var(--space-5);
                padding-left: 0;
            }
            &.local-state {
                .box-wrap {
                    flex-direction: column;
                    gap: var(--space-9);
                    .box {
                        max-width: unset;
                    }
                    .sub-title2 {
                        margin-top: var(--space-8);
                    }
                }
            }
        }
    }
    .enforce-direction {
        .rt-ContainerInner {
            padding-inline: 0;
        }
        .direction-box {
            padding-bottom: var(--space-12);
            border: none;
        }
        .box-img {
            height: 288px;
            padding-inline: var(--space-3);
        }
        .goal-wrapper {
            margin-top: var(--space-12);
            padding-inline: var(--space-5);
            .wrap-goals {
                flex-direction: column;
                gap: var(--space-12);
                margin-top: var(--space-12);
                .box-goals {
                    width: 100%;
                    .rt-Text {
                        color: var(--font-darken);
                    }
                    .box-goals-em {
                        margin-top: var(--space-1);
                        .em {
                            margin-bottom: var(--space-5);
                        }
                    }
                }
            }
        }
        .key-task-wrapper {
            margin-top: var(--space-12);
            padding-top: var(--space-12);
            padding-inline: var(--space-5);
            .key-task {
                flex-direction: column;
                .key-task-box {
                    width: 100%;
                    padding: var(--space-8) var(--space-3);
                    .key-task-box-flex {
                        gap: var(--space-10);
                    }
                    .titles {
                        margin-bottom: var(--space-10);
                    }
                    .title-box {
                        width: 100%;
                        height: 44px;
                    }
                    &.primary {
                        .title-box {
                            background-image: url('../../../assets/images/pages/policy/bg-key-task-header-mo.svg');
                        }
                        &::after {
                            top: unset;
                            bottom: -86px;
                            left: 50%;
                            transform: translateX(-50%) rotateZ(90deg);
                            transform-origin: center;
                        }
                    }
                    &.blue {
                        .title-box {
                            background-image: url('../../../assets/images/pages/policy/bg-key-task-header-blue-mo.svg');
                        }
                    }
                    .key-task-area {
                        padding-inline: var(--space-4);
                        &.is-arrow {
                            margin-bottom: var(--space-8);
                        }
                    }
                }
            }
        }
    }
    .digital-view {
        .rt-ContainerInner {
            padding-inline: 0;
        }
        .digital-view-box {
            padding-inline: var(--space-5);
            img {
                width: 100%;
            }
        }
        .jobs {
            img {
                flex-shrink: 0;
                width: 93px;
                box-shadow: var(--shadow-4);
                border-radius: 100%;
            }
        }
    }
}
