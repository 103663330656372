.breadcrumbs-box {
  padding: calc(var(--space-5) - 1px) 0;
  border-bottom: 1px solid var(--border-light);
  .breadcrumbs {
    padding: calc(var(--space-1) - 2px) 0;
    .home {
      position: relative;
      margin-left: auto;
      margin-right: calc(var(--space-5) - 2px);
      width: 24px;
      height: 24px;
      background: url('../../../assets/images/icon/icon-home.svg');
      font-size: 0;
      text-indent: -9999rem;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: calc((var(--space-5) - 2px) * -1);
        width: 1px;
        height: 24px;
        background-color: var(--border-light);
        transform: translateY(-50%);
      }
    }
    .button-wrap {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 24px;
        background-color: var(--border-light);
        transform: translateY(-50%);
      }
      &:last-of-type {
        padding-right: var(--space-9);
        button {
          width: 100%;
        }
      }
    }
    button {
      position: relative;
      margin: 0 calc(var(--space-5) - 2px);
      min-width: 132px;
      color: var(--font-basic);
      text-align: left;

      img {
        position: absolute;
        top: 50%;
        right: 0;
        width: 24px;
        height: 24px;
        transition: all 500ms ease-in-out;
      }

      .down {
        transform: translateY(-50%) rotate(0deg);
      }
      .up {
        transform: translateY(-50%) rotate(180deg);
      }
      .text {
        position: absolute;
        left: -9999px;
        white-space: nowrap;
      }
      //   &::after {
      //     content: '';
      //     position: absolute;
      //     top: 50%;
      //     right: 0;
      //     width: 24px;
      //     height: 24px;
      //     background-image: url('../../../assets/images/icon/icon-down.svg');
      //     background-position: center;
      //     background-repeat: no-repeat;
      //     background-size: contain;
      //     transform: translateY(-50%);
      //     transition: all 500ms ease-in-out;
      //   }
      &:focus,
      .active {
        &::after {
          transform: rotate(180deg) translateY(50%);
        }
        & + ul {
          display: block;
        }
      }
    }
    ul {
      //display: none;
      overflow: hidden;
      position: absolute;
      top: calc(var(--space-12) + 3px);
      left: 0;
      width: 100%;
      border: 1px solid var(--border-light);
      background-color: var(--bg-white);
      z-index: 2;

      li {
        a {
          display: block;
          padding: var(--space-3) var(--space-4);
          // color: var(--font-light);

          &:hover,
          &:focus {
            background-color: var(--bg-light);
            color: var(--font-darken);
            font-weight: 700;
          }
          &.selected {
            position: relative;
            color: var(--color-primary);
            font-weight: bold;
            &::after {
              position: absolute;
              top: calc(50% - 2px);
              left: var(--space-1);
              content: '';
              background-color: var(--color-primary);
              width: var(--space-1);
              height: var(--space-1);
            }
          }
        }
      }
    }
  }
  .buttons {
    margin-left: auto;
    margin-right: var(--space-6);
    .rt-IconButton {
      margin-left: var(--space-4);
      width: 24px;
      height: 24px;
      color: var(--font-basic);
      &:hover,
      &:focus {
        background-color: transparent;
        color: var(--font-darken);
      }
    }
  }
}
[data-device='mobile'] {
  .breadcrumbs-box {
    display: none;
  }
}
