.innovation {
  .explain-box {
    display: flex;
    margin-bottom: var(--space-20);
    padding-inline: var(--space-9);
    padding-bottom: var(--space-20);
    border-bottom: 1px solid var(--border-basic);
    gap: var(--space-5);
    .title-box {
      flex-shrink: 0;
      width: 286px;
    }
    .desc-box {
      flex: 1 1;
      color: var(--font-basic);
      strong {
        color: var(--color-primary);
      }
    }
    .image-box {
      flex-shrink: 0;
      margin-right: var(--space-21);
    }
  }
  .missions-wrap {
    padding-inline: var(--space-9);

    .title-circle {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 274px;
      margin-inline: auto;
      background-color: var(--bg-lighten);
      box-shadow: var(--shadow-3);
      border-radius: 100%;
      aspect-ratio: 1/1;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        transform: translate(-50%, -50%);
        width: 274px;
        background-color: var(--bg-white);
        aspect-ratio: 1/1;
        border-radius: 100%;
        opacity: 0.3;
      }
      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 215px;
        text-align: center;
        background-color: var(--bg-white);
        box-shadow: var(--shadow-3);
        color: var(--font-darken);
        border-radius: 100%;
        aspect-ratio: 1/1;
      }
    }
  }

  .trees {
    width: 1008px;
    margin-top: var(--space-5);
    margin-inline: auto;

    .root {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border-style: solid;
        border-color: transparent;
        border-top-width: calc(
          5px * 1.732
        ); // 1.732를 곱하면 높이대로 border가 만들어짐
        border-top-color: var(--border-dark);
        border-right-width: 10px * 0.5;
        border-left-width: 10px * 0.5;
      }
      .branch {
        height: var(--space-11);
        margin-inline: auto;
      }
    }
    .branches {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-top: 1px solid var(--border-dark);
      .branch {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 100%);
          width: var(--space-2);
          height: var(--space-2);
          border-radius: 100%;
          border: 1px solid var(--border-dark);
          background-color: var(--bg-white);
        }
      }
    }
    .branch {
      width: 1px;
      height: var(--space-6);
      background-color: var(--border-dark);
    }
  }
  .missions {
    margin-top: var(--space-4);

    .mission-triggers {
      display: flex;
      justify-content: space-between;
      box-shadow: none;
    }
    .mission-trigger {
      width: 320px;
      height: 144px;
      padding: 0;
      border: 5px solid var(--border-light);
      color: var(--font-basic);
      cursor: var(--cursor-button);
      &::before,
      .rt-TabsTriggerInnerHidden {
        display: none;
      }

      &:hover {
        border-color: var(--border-darken);
        color: var(--font-darken);
      }

      &[data-state="active"] {
        position: relative;
        padding-bottom: var(--space-4);
        box-sizing: content-box;
        background-image: url("../../../assets/images/pages/introduction/bg-mission-trigger-on.svg");
        background-repeat: no-repeat;
        border: none;
        color: var(--font-white);
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 131px;
          background-image: url("../../../assets/images/pages/introduction/bg-mission-trigger-on-2.svg");
          background-repeat: no-repeat;
        }
        .rt-TabsTriggerInner {
          position: relative;
          z-index: 1;
        }
      }
      .rt-TabsTriggerInner {
        font-size: var(--font-size-6);
        font-weight: var(--font-weight-bold);
        background: none;
      }
    }
  }
  .mission {
    margin-top: var(--space-18);

    .body1 {
      color: var(--font-basic);
    }

    .mission-list {
      margin-top: var(--space-16);
      .title {
        display: flex;
        align-items: center;
        gap: var(--space-3);
        &::before {
          content: "";
          display: inline-block;
          width: var(--space-3);
          height: var(--space-3);
          margin-top: var(--space-1);
          background-color: var(--color-primary);
        }
      }
      .text-point {
        color: var(--color-primary);
      }
    }
  }
}

html[data-device="mobile"] {
  .innovation {
    .explain-box {
      margin-bottom: var(--space-16);
      padding-inline: 0;
      padding-bottom: var(--space-16);
      flex-direction: column;

      .image-box {
        margin: 0;
        margin-top: var(--space-17);
      }
    }
    .missions-wrap {
      padding-inline: 0;
      .title-circle {
        width: 100%;
        max-width: 274px;
        .title {
          width: 100%;
          max-width: 215px;
        }
      }
    }
    .mission-trigger {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      max-width: 335px;
      max-height: 94px;
      margin-top: var(--space-9);
      margin-inline: auto;
      padding-bottom: var(--space-4);
      box-sizing: content-box;
      background-image: url("../../../assets/images/pages/introduction/bg-mission-trigger-mo.svg");
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 78px;
        background-image: url("../../../assets/images/pages/introduction/bg-mission-trigger-mo-2.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
      .inner {
        position: relative;
        z-index: 1;
        font-size: var(--font-size-5);
        font-weight: var(--font-weight-bold);
        color: var(--font-white);
        background: none;
      }
    }
    .mission {
      margin-top: var(--space-6);
      .body2 {
        color: var(--font-basic);
      }
      .mission-list {
        margin-top: var(--space-5);
      }
    }
  }
}
