footer {
  margin-top: var(--space-45);
  border-top: 1px solid var(--border-light);
  .logo {
    width: 138px;
    height: 48px;
    background: url('../../../assets/images/common/logo.svg') no-repeat center/ contain;
    font-size: 0px;
    text-indent: -9999rem;
  }
  .terms-wrap {
    column-gap: var(--space-6);
    .rt-variant-ghost {
      padding: 0;
      margin: 0;
      font-weight: var(--font-weight-regular);
    }
  }
  .info-wrap {
    border-top: 1px solid var(--border-light);
    padding: calc(var(--space-5) - 1px) 0 calc(var(--space-11) - 1px);
    color: var(--font-basic);
    .info {
      flex-grow: 1;
      margin-top: calc(var(--space-1) + 1px);
      margin-left: calc(var(--space-9) - 1px);
    }
    .terms-wrap {
      column-gap: var(--space-6);
      .rt-variant-ghost {
        padding: 0;
        margin: 0;
        font-weight: var(--font-weight-regular);
      }
      button {
        &:hover,
        &:focus {
          text-decoration: underline;
          text-underline-position: under;
          text-underline-offset: 2px;
        }
      }
    }
    .tel,
    .fax {
      display: none;
    }
    .copyright {
      margin-top: var(--space-1);
      color: var(--font-basic);
      font-size: var(--font-size-2);
    }
  }
}
[data-device='mobile'] {
  footer {
    margin-top: var(--space-16);
    border-top: 0;
    .terms-wrap {
      flex-wrap: wrap;
      column-gap: var(--space-3);
      row-gap: var(--space-2);
      padding-top: var(--space-6);
      border-top: 1px solid var(--border-darken);
    }
    .family-site {
      margin-top: var(--space-6);
      width: 100%;
    }
    .info-wrap {
      display: block;
      padding: 0 0 var(--space-9);
      border-top: 0;
      .info {
        margin-left: 0;
      }
      ul {
        flex-wrap: wrap;
      }
      .address {
        width: 100%;
      }
      .number {
        display: none;
      }
      .tel,
      .fax {
        display: block;
      }
    }
  }
  @media screen and (min-width: 1080px) {
    footer {
      border-top: 1px solid var(--border-light);
      .family-site {
        margin-top: 0;
        width: 256px;
      }
      .info-wrap {
        display: flex;
        padding: calc(var(--space-5) - 1px) var(--space-10) calc(var(--space-3) - 1px);
        border-top: 1px solid var(--border-light);
        .info {
          margin-left: calc(var(--space-5) + 1px);
        }
        .address {
          width: auto;
        }
      }
    }
  }
}
