.best-practices {
    .award-tabs {
        margin-top: var(--space-15);
        width: 100%;
        .award-button {
            flex-grow: 1;
            flex-direction: column;
            padding: var(--space-6) 0;
            height: 146px;
            font-size: calc(var(--default-font-size) * 1.5);
            font-weight: var(--font-weight-regular);
            border-color: var(--border-basic);
            background-color: var(--bg-light);
            // color: var(--font-light);
            img {
                // opacity: 20%;
								filter: grayscale(100%);
            }
            &.active {
                border-color: var(--border-darken);
                background-color: var(--bg-white);
                color: var(--font-darken);
								font-weight: var(--font-weight-bold);
                background: url('../../../assets/images/pages/bestPractices/btn-frame-blue.svg') no-repeat left -1px top -1px / contain;
                img {
                    // opacity: 100%;
										filter: grayscale(0);
                }
            }
        }
        &.merit {
            .award-button {
                &.active {
                    background: url('../../../assets/images/pages/bestPractices/btn-frame-red.svg') no-repeat left -1px top -1px / contain;
                }
            }
        }
    }
    .award-frame {
        width: 100%;
        height: max-content;
        position: relative;
        margin-top: var(--space-4);
        padding: var(--space-3);
        border: var(--space-5) solid var(--bg-dark-blue);
        & + .award-frame {
            margin-top: var(--space-20);
        }
        .award-frame-gold {
            border: 4px solid;
            border-image: linear-gradient(180deg, #ebce81 0%, #bc8130 100%) 1;
            .award-frame-corner-top {
                position: absolute;
                width: 588px;
                height: 100%;
                top: 0;
                left: 0;
                background: url('../../../assets/images/pages/bestPractices/frame-corner-top-blue.png') no-repeat top left / contain;
                &.red {
                    background: url('../../../assets/images/pages/bestPractices/frame-corner-top-red.png') no-repeat top left / contain;
                }
            }
            .award-frame-corner-bottom {
                position: absolute;
                width: 100%;
                height: 495px;
                bottom: 0;
                right: 0;
                background: url('../../../assets/images/pages/bestPractices/frame-corner-bottom-blue.png') no-repeat bottom right / contain;
                &.red {
                    background: url('../../../assets/images/pages/bestPractices/frame-corner-bottom-red.png') no-repeat bottom right /
                        contain;
                }
            }
        }
    }
    .award-wrap {
        padding: var(--space-30) var(--space-20) 320px;
        .award-title {
            margin-bottom: var(--space-33);
        }
        .award-img-box {
            width: 300px;
            height: 242px;
            position: relative;
            .laurel-img {
                width: 100%;
                height: auto;
                position: absolute;
                bottom: 0;
            }
            .award-img {
                width: 180px;
                height: 180px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 50%;
                overflow: hidden;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .award-text-box {
            flex: 1;
            p {
                word-break: keep-all;
            }
        }
    }
}
[data-device='mobile'] {
    .best-practices {
        .award-tabs {
            margin-top: var(--space-12);
            .award-button {
                height: 144px;
                font-size: calc(var(--default-font-size) * 1.25);
                &.active {
                    background-size: 96px;
                }
            }
        }
        .award-frame {
            border: var(--space-2) solid var(--bg-dark-blue);
            padding: var(--space-1);
            width: 100%;
            & + .award-frame {
                margin-top: var(--space-12);
            }
            .award-frame-corner-top {
                width: 100%;
                height: 220px;
            }
            .award-frame-corner-bottom {
                width: 320px;
                height: 100%;
            }
        }
        .award-wrap {
            padding: var(--space-31) var(--space-4) 280px;
            .award-title {
                margin-bottom: var(--space-28);
                .award-host {
                    align-self: flex-end;
                    img {
                        width: 138px;
                        height: auto;
                    }
                }
            }
            .award-img-box {
                width: 208px;
                height: 168px;
                .award-img {
                    width: 124px;
                    height: 124px;
                }
            }
        }
    }
}
