// 최상단 배너
.top-banner {
  width: 100%;
  height: 700px;
  padding-block: var(--space-18);
  background: #25282f;
  p {
    color: var(--font-white);
    &::before {
      content: '';
      display: block;
      width: 330px;
      height: 115px;
      margin-bottom: var(--space-1);
      background: url(../../assets/images/pages/main/logo-main-w.svg) no-repeat top left / contain;
    }
  }
  span {
    color: #5faeef;
  }
  img {
    width: 720px;
    height: auto;
  }
}

.main {
  .main-banner {
    .banner-item {
      width: 100%;
      min-height: 550px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.type-dark {
        .banner-text,
        a {
          color: var(--font-white);
        }
        .rt-Separator {
          background-color: var(--border-white);
        }
      }
      &.type-light {
        .banner-text,
        a {
          color: var(--font-darken);
          li {
            color: var(--font-basic);
          }
        }
        .rt-Separator {
          background-color: var(--border-basic);
        }
      }
      &.bg-primary {
        background: var(--color-primary);
      }
      &.bg-navy {
        background: var(--blue-9);
      }
      &.bg-blue {
        background: var(--color-info);
      }
      .rt-Separator {
        width: 73px;
        background-color: var(--border-white);
        opacity: 0.36;
      }
    }
    .banner-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: var(--font-white);
      row-gap: var(--space-8);
      padding: var(--space-10) var(--space-8) var(--space-8) var(--space-10);
      li {
        font-size: var(--font-size-4);
        font-weight: var(--font-weight-bold);
        line-height: calc(var(--default-font-size) * 2);
        &:nth-of-type(n + 2) {
          margin-top: var(--space-1);
        }
      }
    }
    .banner-img {
      width: 100%;
      img {
        width: 100%;
      }
    }
    a:hover, a:focus{
      text-decoration: underline;
    }
  }

  .business-items {
    gap: var(--space-3) var(--space-10);
    a {
      display: flex;
      align-items: center;
      width: 440px;
      height: 120px;
      padding: var(--space-10) var(--space-6) var(--space-10) var(--space-12);
      border: 1px solid var(--border-light);
      background-color: var(--bg-light);
      color: var(--font-darken);
      &:hover,
      &:focus,
      &:active {
        border-color: var(--border-light-blue);
        background-color: var(--bg-light-blue);
        color: var(--blue-9);
        .view {
          color: var(--color-info);
          &::after {
            background-image: url('../../assets/images/pages/main/icon-button-view-blue.svg');
          }
        }
      }
    }
    .view {
      position: relative;
      margin-left: auto;
      padding-right: var(--space-12);
      color: var(--font-light);
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 35px;
        height: 16px;
        background: url('../../assets/images/pages/main/icon-button-view.svg') no-repeat center/ contain;
        transform: translateY(-50%);
      }
    }
  }

  .main-info {
    min-height: 366px;
    align-items: stretch;
    margin-top: var(--space-21);
    gap: var(--space-6);
    .info-title {
      width: 332px;
      .more-btn {
        width: 94px;
        color: var(--font-darken);
        position: relative;
        cursor: pointer;
        &:hover {
          color: var(--color-info);
          &::after {
            background-image: url(../../assets//images//pages//main/icon-button-view-blue.svg);
          }
        }
        &::after {
          content: '';
          display: block;
          width: 35px;
          height: 16px;
          background: url(../../assets//images/pages/main/icon-button-view-dark.svg) no-repeat center / contain;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
    }

    .info-items {
      width: 688px;
      height: inherit;
      border-top: 1px solid var(--border-darken);
    }
    .info-point {
      display: flex;
      align-items: center;
      gap: var(--space-6);
      padding: var(--space-6) var(--space-4);
      border-bottom: 1px solid var(--border-light);
      .title {
        width: 520px;
        height: 60px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: keep-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        white-space: unset;
      }
      .date {
        width: 160px;
        height: 90px;
        padding: 6px 8px;
      }
    }

    .info-video {
      width: 332px;
      height: inherit;
      display: block;
      background-color: #25265c;
      padding: var(--space-7);
			color: var(--font-white);
      .rt-Flex {
        height: 100%;
      }
      img {
        margin-top: auto;
      }
    }
    a:hover, a:focus{
      .title{
        text-decoration: underline;
      }
    }
  }

  .introduction-items {
    justify-content: space-between;
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: var(--space-9);
      padding-right: var(--space-6);
      width: 320px;
      height: 130px;
      border: 1px solid var(--border-light);
      color: var(--font-darken);
      font-size: var(--font-size-5);
      font-weight: var(--font-weight-bold);
      line-height: calc(var(--default-font-size) * 2);
      &:hover, &:focus{
        text-decoration: underline;
        border-color:var(--border-darken);
      }
    }
    .item {
      &.dreams {
        span {
          background-image: url('../../assets/images/pages/main/icon-intro-dreams.svg');
        }
      }
      &.policy {
        span {
          background-image: url('../../assets/images/pages/main/icon-intro-policy.svg');
        }
      }
      &.sw {
        span {
          background-image: url('../../assets/images/pages/main/icon-intro-sw.svg');
        }
      }
      &.best {
        span {
          background-image: url('../../assets/images/pages/main/icon-intro-best.svg');
        }
      }
      span {
        margin-left: auto;
        width: 100px;
        height: 100px;
        background-image: url('../../assets/images/pages/main/icon-intro-dreams.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        font-size: 0;
      }
    }
  }
}

html[data-device='mobile'] {
  // 최상단 배너
  .top-banner {
    height: auto;
    padding-block: var(--space-12);
    p {
      &::before {
        width: 180px;
        height: 60px;
      }
    }
    img {
      width: 100%;
    }
  }

  .business-items {
    a {
      padding: var(--space-6);
      width: 100%;
      height: 82px;
    }
    .view {
      font-size: 0;
    }
  }

  .main-info {
    margin-top: var(--space-16);
    gap: unset;
    .info-title {
      width: 100%;
      margin-bottom: var(--space-6);
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title5 {
          color: var(--font-darken);
          margin-bottom: var(--space-2);
        }
      }
    }
    .info-items {
      width: 100%;
      .info-point {
        flex-direction: column;
        align-items: flex-start;
        gap: var(--space-8);
        padding: var(--space-6) 0;
        border-bottom: 1px dashed var(--border-light);
        .date {
          align-self: center;
        }
        .title {
          width: 100%;
          padding-inline: var(--space-3);
        }
      }
      .info-sub {
        padding: var(--space-3);
        width: 100%;
        & + .info-sub {
          border-top: 1px dashed var(--border-light);
        }
      }
    }
    .info-video {
      margin-top: var(--space-13);
    }
  }

  .introduction-items {
    flex-wrap: wrap;
    gap: var(--space-3);
    a {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: var(--space-3);
      width: 160px;
      height: 160px;
      font-size: var(--font-size-4);
      font-weight: var(--font-weight-bold);
      line-height: calc(var(--default-font-size) * 2);
    }
    .item {
      span {
        margin-top: auto;
        margin-left: unset;
      }
    }
  }
}
