// select
.custom-select-box {
  &.family-site {
    width: 256px;
  }

  &.search-select {
    width: 200px;

    .select-popover {
      width: 200px;
    }
  }
}
.select-trigger {
  color: var(--font-basic);
  display: flex;
  align-items: center;
  gap: calc(var(--space-3) - 2px);
  padding: 0 var(--space-2) 0 var(--space-4);
  height: var(--space-12);
  width: 100%;
  border: 1px solid var(--border-light);
  background-color: var(--bg-white);
  box-shadow: none;
  img {
    width: 24px;
    height: 24px;
    margin-left: auto;
    transition: all 500ms ease-in-out;
  }
  .up {
    transform: rotate(180deg);
  }
  .down {
    transform: rotate(0deg);
  }
  &:hover,
  &:focus {
    border-color: var(--border-darken);
  }
}
.select-popover {
  position: absolute;
  border: 1px solid var(--border-basic);
  background-color: white;
  margin-top: -1px;
  width: 256px;
  z-index: 2;

  a,
  button {
    color: var(--font-basic);
    text-align: left;
    width: 100%;
    display: block;
    padding: var(--space-3);
    font-size: var(--default-font-size);

    &:hover,
    &:focus {
      background-color: var(--bg-light);
      color: var(--font-darken);
      font-weight: 700;
    }
  }
}
